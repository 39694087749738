import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c582346c = () => interopDefault(import('../components/pages/glamping2.vue' /* webpackChunkName: "" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _1bdb5b02 = () => interopDefault(import('../pages/counter.vue' /* webpackChunkName: "pages/counter" */))
const _5fa7aea0 = () => interopDefault(import('../pages/counter2.vue' /* webpackChunkName: "pages/counter2" */))
const _52acf5bc = () => interopDefault(import('../pages/desktop.vue' /* webpackChunkName: "pages/desktop" */))
const _cf0a665e = () => interopDefault(import('../pages/review-awards.vue' /* webpackChunkName: "pages/review-awards" */))
const _98fb5844 = () => interopDefault(import('../pages/version.vue' /* webpackChunkName: "pages/version" */))
const _7461931a = () => interopDefault(import('../pages/about/faq.vue' /* webpackChunkName: "pages/about/faq" */))
const _96f4decc = () => interopDefault(import('../pages/about/resend_confirmation_email.vue' /* webpackChunkName: "pages/about/resend_confirmation_email" */))
const _76f5b26e = () => interopDefault(import('../pages/account/bookings/index.vue' /* webpackChunkName: "pages/account/bookings/index" */))
const _e3328d14 = () => interopDefault(import('../pages/account/favourites.vue' /* webpackChunkName: "pages/account/favourites" */))
const _a888e282 = () => interopDefault(import('../pages/account/reviews.vue' /* webpackChunkName: "pages/account/reviews" */))
const _7e73b66a = () => interopDefault(import('../pages/accounts/login.vue' /* webpackChunkName: "pages/accounts/login" */))
const _c5d7c14e = () => interopDefault(import('../pages/accounts/logout.vue' /* webpackChunkName: "pages/accounts/logout" */))
const _c745f832 = () => interopDefault(import('../pages/accounts/signup.vue' /* webpackChunkName: "pages/accounts/signup" */))
const _60c9f702 = () => interopDefault(import('../pages/campsites/near-me.vue' /* webpackChunkName: "pages/campsites/near-me" */))
const _63f99016 = () => interopDefault(import('../pages/caravans/near-me.vue' /* webpackChunkName: "pages/caravans/near-me" */))
const _436868db = () => interopDefault(import('../pages/e2e/filestack.vue' /* webpackChunkName: "pages/e2e/filestack" */))
const _ad9450d4 = () => interopDefault(import('../pages/errors/asyncError.vue' /* webpackChunkName: "pages/errors/asyncError" */))
const _3f6b785d = () => interopDefault(import('../pages/errors/customError.vue' /* webpackChunkName: "pages/errors/customError" */))
const _a24601a0 = () => interopDefault(import('../pages/errors/handledEventError.vue' /* webpackChunkName: "pages/errors/handledEventError" */))
const _0e7c93e0 = () => interopDefault(import('../pages/errors/handleRenderError.vue' /* webpackChunkName: "pages/errors/handleRenderError" */))
const _5ba686b7 = () => interopDefault(import('../pages/errors/unhandledEventError.vue' /* webpackChunkName: "pages/errors/unhandledEventError" */))
const _f828a38e = () => interopDefault(import('../pages/errors/unhandledRenderError.vue' /* webpackChunkName: "pages/errors/unhandledRenderError" */))
const _546ef97b = () => interopDefault(import('../pages/glamping/near-me.vue' /* webpackChunkName: "pages/glamping/near-me" */))
const _75cacfa8 = () => interopDefault(import('../pages/lodges/near-me.vue' /* webpackChunkName: "pages/lodges/near-me" */))
const _10b7d93c = () => interopDefault(import('../pages/tourers/near-me.vue' /* webpackChunkName: "pages/tourers/near-me" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _47da89a8 = () => interopDefault(import('../pages/reviews/post-review/campsite/_campsite.vue' /* webpackChunkName: "pages/reviews/post-review/campsite/_campsite" */))
const _56607bc4 = () => interopDefault(import('../pages/about/faqs/_slug.vue' /* webpackChunkName: "pages/about/faqs/_slug" */))
const _69800507 = () => interopDefault(import('../pages/account/bookings/_prettyId.vue' /* webpackChunkName: "pages/account/bookings/_prettyId" */))
const _55f3d661 = () => interopDefault(import('../pages/accounts/confirm_email/_key.vue' /* webpackChunkName: "pages/accounts/confirm_email/_key" */))
const _726eff78 = () => interopDefault(import('../pages/booking/make_another_booking/_pretty_id.vue' /* webpackChunkName: "pages/booking/make_another_booking/_pretty_id" */))
const _1b1b2ef3 = () => interopDefault(import('../pages/booking/paypal_callback/_bookingId.vue' /* webpackChunkName: "pages/booking/paypal_callback/_bookingId" */))
const _462dc50f = () => interopDefault(import('../pages/booking/thanks/_signature.vue' /* webpackChunkName: "pages/booking/thanks/_signature" */))
const _04ec4b7e = () => interopDefault(import('../pages/accounts/reset/_id/_token/index.vue' /* webpackChunkName: "pages/accounts/reset/_id/_token/index" */))
const _533a4c49 = () => interopDefault(import('../pages/booking/payment/_paymentId/_bookingId.vue' /* webpackChunkName: "pages/booking/payment/_paymentId/_bookingId" */))
const _b80d14a0 = () => interopDefault(import('../pages/campsite/_slug/index.vue' /* webpackChunkName: "pages/campsite/_slug/index" */))
const _02647cfb = () => interopDefault(import('../pages/campsite/_slug/book.vue' /* webpackChunkName: "pages/campsite/_slug/book" */))
const _00dc2312 = () => interopDefault(import('../pages/campsite/_slug/check-availability.vue' /* webpackChunkName: "pages/campsite/_slug/check-availability" */))
const _6b6129b0 = () => interopDefault(import('../pages/campsite/_slug/details/_.vue' /* webpackChunkName: "pages/campsite/_slug/details/_" */))
const _97e642fa = () => interopDefault(import('../pages/searcheditmaps/_.vue' /* webpackChunkName: "pages/searcheditmaps/_" */))
const _5520472c = () => interopDefault(import('../pages/searchedit/_.vue' /* webpackChunkName: "pages/searchedit/_" */))
const _80887de8 = () => interopDefault(import('../components/pages/SearchPage.vue' /* webpackChunkName: "" */))
const _698820ee = () => interopDefault(import('../components/pages/CampsiteDetail.vue' /* webpackChunkName: "" */))
const _74d58200 = () => interopDefault(import('../apps/campsite-albums/CampsiteAlbums.vue' /* webpackChunkName: "" */))
const _4e0a2800 = () => interopDefault(import('../apps/campsite-albums/AlbumVideo.vue' /* webpackChunkName: "" */))
const _e788f468 = () => interopDefault(import('../apps/campsite-albums/AlbumPhotos.vue' /* webpackChunkName: "" */))
const _7cef97c4 = () => interopDefault(import('../components/pages/GlampingCategorySearch.vue' /* webpackChunkName: "" */))
const _19fa2805 = () => interopDefault(import('../apps/maps-search/MapsPage.vue' /* webpackChunkName: "" */))
const _5eaacafb = () => interopDefault(import('../components/pages/CategorySearch.vue' /* webpackChunkName: "" */))
const _2d918ada = () => interopDefault(import('../apps/flatpages/FlatPageComponent.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/glamping/",
    component: _c582346c,
    meta: {"type":"glamping","category":"glamping","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "glamping2-root"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/account/",
    component: _cbd4fa14,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/counter/",
    component: _1bdb5b02,
    pathToRegexpOptions: {"strict":true},
    name: "counter"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/counter2/",
    component: _5fa7aea0,
    pathToRegexpOptions: {"strict":true},
    name: "counter2"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/desktop/",
    component: _52acf5bc,
    pathToRegexpOptions: {"strict":true},
    name: "desktop"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/review-awards/",
    component: _cf0a665e,
    pathToRegexpOptions: {"strict":true},
    name: "review-awards"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/version/",
    component: _98fb5844,
    pathToRegexpOptions: {"strict":true},
    name: "version"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/about/faq/",
    component: _7461931a,
    pathToRegexpOptions: {"strict":true},
    name: "about-faq"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/about/resend_confirmation_email/",
    component: _96f4decc,
    pathToRegexpOptions: {"strict":true},
    name: "about-resend_confirmation_email"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/account/bookings/",
    component: _76f5b26e,
    pathToRegexpOptions: {"strict":true},
    name: "account-bookings"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/account/favourites/",
    component: _e3328d14,
    pathToRegexpOptions: {"strict":true},
    name: "account-favourites"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/account/reviews/",
    component: _a888e282,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/accounts/login/",
    component: _7e73b66a,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-login"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/accounts/logout/",
    component: _c5d7c14e,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-logout"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/accounts/signup/",
    component: _c745f832,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-signup"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsites/near-me/",
    component: _60c9f702,
    pathToRegexpOptions: {"strict":true},
    name: "campsites-near-me"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/caravans/near-me/",
    component: _63f99016,
    pathToRegexpOptions: {"strict":true},
    name: "caravans-near-me"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/e2e/filestack/",
    component: _436868db,
    pathToRegexpOptions: {"strict":true},
    name: "e2e-filestack"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/asyncError/",
    component: _ad9450d4,
    pathToRegexpOptions: {"strict":true},
    name: "errors-asyncError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/customError/",
    component: _3f6b785d,
    pathToRegexpOptions: {"strict":true},
    name: "errors-customError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/handledEventError/",
    component: _a24601a0,
    pathToRegexpOptions: {"strict":true},
    name: "errors-handledEventError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/handleRenderError/",
    component: _0e7c93e0,
    pathToRegexpOptions: {"strict":true},
    name: "errors-handleRenderError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/unhandledEventError/",
    component: _5ba686b7,
    pathToRegexpOptions: {"strict":true},
    name: "errors-unhandledEventError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/errors/unhandledRenderError/",
    component: _f828a38e,
    pathToRegexpOptions: {"strict":true},
    name: "errors-unhandledRenderError"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/glamping/near-me/",
    component: _546ef97b,
    pathToRegexpOptions: {"strict":true},
    name: "glamping-near-me"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/lodges/near-me/",
    component: _75cacfa8,
    pathToRegexpOptions: {"strict":true},
    name: "lodges-near-me"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/tourers/near-me/",
    component: _10b7d93c,
    pathToRegexpOptions: {"strict":true},
    name: "tourers-near-me"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/reviews/post-review/campsite/:campsite?/",
    component: _47da89a8,
    pathToRegexpOptions: {"strict":true},
    name: "reviews-post-review-campsite-campsite"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/about/faqs/:slug?/",
    component: _56607bc4,
    pathToRegexpOptions: {"strict":true},
    name: "about-faqs-slug"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/account/bookings/:prettyId/",
    component: _69800507,
    pathToRegexpOptions: {"strict":true},
    name: "account-bookings-prettyId"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/accounts/confirm_email/:key?/",
    component: _55f3d661,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-confirm_email-key"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/booking/make_another_booking/:pretty_id?/",
    component: _726eff78,
    pathToRegexpOptions: {"strict":true},
    name: "booking-make_another_booking-pretty_id"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/booking/paypal_callback/:bookingId?/",
    component: _1b1b2ef3,
    pathToRegexpOptions: {"strict":true},
    name: "booking-paypal_callback-bookingId"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/booking/thanks/:signature?/",
    component: _462dc50f,
    pathToRegexpOptions: {"strict":true},
    name: "booking-thanks-signature"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/accounts/reset/:id?/:token/",
    component: _04ec4b7e,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-reset-id-token"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/booking/payment/:paymentId?/:bookingId?/",
    component: _533a4c49,
    pathToRegexpOptions: {"strict":true},
    name: "booking-payment-paymentId-bookingId"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsite/:slug/",
    component: _b80d14a0,
    pathToRegexpOptions: {"strict":true},
    name: "campsite-slug"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsite/:slug?/book/",
    component: _02647cfb,
    pathToRegexpOptions: {"strict":true},
    name: "campsite-slug-book"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsite/:slug?/check-availability/",
    component: _00dc2312,
    pathToRegexpOptions: {"strict":true},
    name: "campsite-slug-check-availability"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsite/:slug?/details/*",
    component: _6b6129b0,
    pathToRegexpOptions: {"strict":true},
    name: "campsite-slug-details-all"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/searcheditmaps/*",
    component: _97e642fa,
    pathToRegexpOptions: {"strict":true},
    name: "searcheditmaps-all"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/searchedit/*",
    component: _5520472c,
    pathToRegexpOptions: {"strict":true},
    name: "searchedit-all"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/about/faq/",
    component: _7461931a,
    meta: {},
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/search/*/",
    component: _80887de8,
    meta: {"type":"campsites","isSeo":false,"app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/search/",
    component: _80887de8,
    meta: {"type":"campsites","isSeo":false,"app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "search-root"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsites/:h1?/:h2?/:h3?/:h4?/-/:facets*/",
    component: _80887de8,
    meta: {"type":"campsites","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "campsites-search-facet"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsites/:h1/:h2/:h3/:h4/:slug/",
    component: _698820ee,
    meta: {"type":"campsite-details","app":"campsite-details","doCheckRoute":true},
    pathToRegexpOptions: {"strict":true},
    name: "campsite-detail"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsite/:slug/albums/",
    component: _74d58200,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "video/",
      component: _4e0a2800,
      pathToRegexpOptions: {"strict":true},
      name: "campsite-albums-video"
    }, {
      path: "",
      component: _e788f468,
      pathToRegexpOptions: {"strict":true},
      name: "campsite-albums-album-all"
    }, {
      path: ":tag?/:albumId?/",
      component: _e788f468,
      pathToRegexpOptions: {"strict":true},
      name: "campsite-albums-album"
    }]
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsites/*/",
    component: _80887de8,
    meta: {"type":"campsites","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "campsites-search"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/campsites/",
    component: _80887de8,
    meta: {"type":"campsites","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "campsites-search-root"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/glamping/*/",
    component: _7cef97c4,
    meta: {"type":"glamping","category":"glamping","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "glamping-search"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/glamping/",
    component: _7cef97c4,
    meta: {"type":"glamping","category":"glamping","app":"search"},
    pathToRegexpOptions: {"strict":true},
    name: "glamping-root"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/maps/*/",
    component: _19fa2805,
    meta: {"type":"maps","app":"maps"},
    pathToRegexpOptions: {"strict":true},
    name: "maps"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/maps/",
    component: _19fa2805,
    meta: {"type":"maps","app":"maps"},
    pathToRegexpOptions: {"strict":true},
    name: "maps-root"
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/tourers/*/",
    component: _5eaacafb,
    meta: {"type":"tourers","category":"tourers","app":"search"},
    props: {"slug":"tourers"},
    pathToRegexpOptions: {"strict":true},
    name: "category_tourers",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/tents/*/",
    component: _5eaacafb,
    meta: {"type":"tents","category":"tents","app":"search"},
    props: {"slug":"tents"},
    pathToRegexpOptions: {"strict":true},
    name: "category_tents",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/caravans/*/",
    component: _5eaacafb,
    meta: {"type":"caravans","category":"caravans","app":"search"},
    props: {"slug":"caravans"},
    pathToRegexpOptions: {"strict":true},
    name: "category_caravans",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/lodges/*/",
    component: _5eaacafb,
    meta: {"type":"lodges","category":"lodges","app":"search"},
    props: {"slug":"lodges"},
    pathToRegexpOptions: {"strict":true},
    name: "category_lodges",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/motorhomes/*/",
    component: _5eaacafb,
    meta: {"type":"motorhomes","category":"motorhomes","app":"search"},
    props: {"slug":"motorhomes"},
    pathToRegexpOptions: {"strict":true},
    name: "category_motorhomes",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/rent-a-tent/*/",
    component: _5eaacafb,
    meta: {"type":"rent-a-tent","category":"rent-a-tent","app":"search"},
    props: {"slug":"rent-a-tent"},
    pathToRegexpOptions: {"strict":true},
    name: "category_rent-a-tent",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/tourers/",
    component: _5eaacafb,
    meta: {"type":"tourers","category":"tourers","app":"search"},
    props: {"slug":"tourers"},
    pathToRegexpOptions: {"strict":true},
    name: "category_tourers-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/tents/",
    component: _5eaacafb,
    meta: {"type":"tents","category":"tents","app":"search"},
    props: {"slug":"tents"},
    pathToRegexpOptions: {"strict":true},
    name: "category_tents-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/caravans/",
    component: _5eaacafb,
    meta: {"type":"caravans","category":"caravans","app":"search"},
    props: {"slug":"caravans"},
    pathToRegexpOptions: {"strict":true},
    name: "category_caravans-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/lodges/",
    component: _5eaacafb,
    meta: {"type":"lodges","category":"lodges","app":"search"},
    props: {"slug":"lodges"},
    pathToRegexpOptions: {"strict":true},
    name: "category_lodges-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/motorhomes/",
    component: _5eaacafb,
    meta: {"type":"motorhomes","category":"motorhomes","app":"search"},
    props: {"slug":"motorhomes"},
    pathToRegexpOptions: {"strict":true},
    name: "category_motorhomes-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/rent-a-tent/",
    component: _5eaacafb,
    meta: {"type":"rent-a-tent","category":"rent-a-tent","app":"search"},
    props: {"slug":"rent-a-tent"},
    pathToRegexpOptions: {"strict":true},
    name: "category_rent-a-tent-root",
    beforeEnter: function(to, _from, next) {
            if ('type' in to.query) {
              next({
                name: 'search-root',
                query: to.query,
                params: to.params
              });
            } else {
              next();
            }
          }
  }, {
    path: "/:lang(en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)?/*/",
    component: _2d918ada,
    meta: {"type":"flatpage","app":"flatpage"},
    pathToRegexpOptions: {"strict":true},
    name: "flatpage"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
